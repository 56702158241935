import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  FETCH_COMENTARIO_BEGIN,
  FETCH_COMENTARIO_SUCCESS,
  FETCH_COMENTARIO_FAILURE,
  GET_ERRORS,
  FETCH_COMENTARIO_CLEAR
} from "./types";
import { URL_API } from "./urls";
import { initialState } from "../reducers/comentariosReducer";

const MySwal = withReactContent(Swal);

export const clearComentario = () => ({
  type: FETCH_COMENTARIO_CLEAR,
  payload: { comentario: initialState }
});

export const fetchComentarioBegin = () => ({
  type: FETCH_COMENTARIO_BEGIN
});

export const fetchComentarioSuccess = (comentarios, total) => ({
  type: FETCH_COMENTARIO_SUCCESS,
  payload: { comentarios, total }
});

export const fetchComentarioFailure = error => ({
  type: FETCH_COMENTARIO_FAILURE,
  payload: { error }
});

export function fetchComentarios(contrato, documento, funcionario, filialFuncionario, page = 1) {
  return dispatch => {
    dispatch(fetchComentarioBegin());

    const params = {
      contrato,
      documento,
      page,
    }

    if (funcionario) {
      params.funcionario = funcionario;
      params.filialFuncionario = filialFuncionario;
    }

    axios
      .get(URL_API + "comentarios?" + new URLSearchParams(params))
      .then(res => {
        dispatch(fetchComentarioSuccess(res.data.rows, res.data.total));
        return res.data.rows;
      })
      .catch(err => {
        let erro = { message: "Não foi possível obter os dados" };
        if (err.response) {
          erro = err.response.data;
        }
        dispatch(fetchComentarioFailure(erro));
      });
  };
}

export const saveComentario = (
  comentario,
  actions,
  contrato,
  documento,
  funcionario,
  filialFuncionario
) => dispatch => {
  const tmpComentario = {
    ...comentario,
    contrato,
    documento,
    ...(!!funcionario ? { funcionario, filialFuncionario } : {})
  };
  axios
    .post(URL_API + "comentario", tmpComentario)
    .then(_ => {
      MySwal.fire({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        type: "success",
        title: "Comentário salvo com sucesso"
      });
      actions.setSubmitting(false);

      actions.resetForm();
      dispatch(clearComentario());
      dispatch(fetchComentarios(contrato, documento, funcionario, filialFuncionario));
    })
    .catch(err => {
      if (err.response) {
        const data = err.response.data;
        dispatch({
          type: GET_ERRORS,
          payload: { message: typeof data === 'string' ? data : data.message }
        });
        actions.setErrors(data.errors);
      }
      actions.setSubmitting(false);
    });
};
