/* Geral / Bibliotecas */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Calendar, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import MaskedTextInput from "react-text-mask";
import "react-toggle/style.css";
import AsyncSelect from "react-select/lib/Async";
import VMasker from "vanilla-masker";

/* Métodos / Ações */
import {
  fetch,
  saveContrato,
  deleteData,
  changeStatus,
} from "../../actions/contratos";
import { clearErrors } from "../../actions/errors";
import { loadCidadesOptions } from "../../actions/cidades";
import { fetchCnaes, saveCnae } from "../../actions/cnaes";
import { setAppData } from "../../actions/dados";
import { loadFiliaisOptions } from "../../actions/filiais";
import { setModalOpen } from "../../actions/modal";
import { fetchUsuariosByFilialOptions } from "../../actions/usuarios";

/* Componentes */
import Error from "../Error";
import LoadingImage from "../LoadingImage";

/* Botões */
import ButtonSuccess from "../Buttons/ButtonSuccess";
import ButtonGray from "../Buttons/ButtonGray";
import ButtonWarning from "../Buttons/ButtonWarning";

/* Campos */
import Telefone from "../CustomFields/Telefone";
import Cnpj from "../CustomFields/Cnpj";
import Cep from "../CustomFields/Cep";

/* Validação */
import {
  requiredField,
  emailField,
  phoneField,
  cnpjField,
  objectField,
  cepField,
  dateField,
} from "../../validation/formValidation";
import { STATUS } from "../../types/StatusContrato";

/* Cores */
import {
  COLOR_DARK2,
  COLOR_PRIMARY,
  COLOR_DARK,
  COLOR_GREEN,
  COLOR_LIGHT_BLUE,
} from "../../Colors";
import ButtonPrimary from "../Buttons/ButtonPrimary";

const MySwal = withReactContent(Swal);

registerLocale("pt", pt);

class ContratoForm extends Component {
  constructor() {
    super();

    this._isMounted = false;
    this.state = {
      tipo: "tomador",
      novaEmpresa: false,
      cnpjFocus: true,
      meus_cnaes: null,
      outro_cnaes: null,
    };
  }

  abreGestao = () => {
    const tipo = this.props.contrato.tipo
      ? this.props.contrato.tipo
      : this.state.tipo;

    let empresa = {};
    let filial = {};
    let contrato = {
      tipo: tipo,
      idContrato: this.props.contrato.idContrato,
      nome: this.props.contrato.titulo,
    };

    if (tipo === "terceiro") {
      filial = this.props.contrato.terceiro;
      empresa = this.props.contrato.tomador;
    } else {
      filial = this.props.contrato.tomador;
      empresa = this.props.contrato.terceiro;
    }

    this.props.dispatch(
      setAppData({
        filtros: {
          filial: filial,
          empresa,
          contrato,
        },
      })
    );
    this.props.history.push("/gestao-documentos/");
  };

  handleCnaeCreation = (after) => {
    MySwal.mixin({
      input: "text",
      confirmButtonText: "Continuar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      progressSteps: ["1", "2"],
    })
      .queue([
        {
          title: "Código",
          text: "Digite o código do CNAE",
          customClass: {
            input: "maskCNAE",
          },
          onOpen: () => {
            VMasker(document.querySelector(".maskCNAE")).maskPattern(
              "9999-9/99"
            );
          },
          preConfirm: (value) => {
            return new Promise((resolve, reject) => {
              if (value && value?.length === 9) {
                resolve();
              } else {
                reject();
              }
            }).catch(() => {
              Swal.showValidationMessage(`Digite um CNAE válido (7 números)`);
            });
          },
        },
        {
          title: "Descrição",
          confirmButtonText: "Salvar",
          text: "Digite a descrição",
          preConfirm: (value) => {
            return new Promise((resolve, reject) => {
              if (value && value?.length >= 10) {
                resolve();
              } else {
                reject();
              }
            }).catch(() => {
              Swal.showValidationMessage(
                `Digite uma descrição válida (>= 10 caracteres)`
              );
            });
          },
        },
      ])
      .then((result) => {
        if (result.value) {
          let cnae = {
            codigo: result.value[0],
            descricao: result.value[1],
          };
          this.props.dispatch(saveCnae(cnae, after));
        }
      });
  };

  handleSubmit(values, actions) {
    const tipo = this.props.contrato.tipo
      ? this.props.contrato.tipo
      : this.state.tipo;

    this.props.dispatch(
      saveContrato(
        {
          ...values,
          responsaveis: values.responsaveis?.map(
            (responsavel) => responsavel.idUsuario
          ),
        },
        tipo,
        actions,
        this.props.onAfterSave
      )
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.dispatch(fetchCnaes());
    if (this.props.id) this.props.dispatch(fetch(this.props.id));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors) {
      let show = false;
      if (Object.keys(prevProps.errors)?.length) {
        show = true;
      }
      if (show) {
        MySwal.fire({
          type: "error",
          title: prevProps.errors.message,
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
        this.props.dispatch(clearErrors());
      }
    }
  }

  handleDelete() {
    MySwal.fire({
      title: "",
      type: "warning",
      html:
        "Deseja realmente excluir o contrato <b>" +
        this.props.contrato.titulo +
        "</b>, essa ação não pode ser revertida?",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({
          loading: true,
        });
        this.props.dispatch(
          deleteData(
            this.props.contrato.idContrato,
            this.props.onAfterDelete,
            () => {
              this.setState({ loading: false });
            }
          )
        );
      }
    });
  }

  handleChangeStatus() {
    var st = this.props.contrato.status === STATUS.ATIVO ? 0 : 1;
    this.setState({
      loading: true,
    });
    this.props.dispatch(
      changeStatus(
        this.props.contrato.idContrato,
        st,
        () => {
          this.props.onAfterSave();
          if (st === 1) {
          }
          this.abreGestao();
        },
        () => {
          this.setState({ loading: false });
        }
      )
    );
  }

  render() {
    if (this.props.error) {
      return <Error erro={this.props.error}></Error>;
    }

    if (this.props.loading) {
      return <LoadingImage></LoadingImage>;
    }

    const loadCnaesOptions = (inputValue) => {
      return new Promise((resolve) => {
        let dados = this.props.cnaes.dados;
        if (inputValue !== "") {
          dados = dados.filter(
            (i) =>
              i.codigo.toLowerCase().includes(inputValue.toLowerCase()) ||
              i.descricao.toLowerCase().includes(inputValue.toLowerCase())
          );
        }
        dados = dados.slice(0, 30);
        resolve(dados);
      });
    };

    const getListResponsaveis = (responsaveis) =>
      responsaveis.map((responsavel) => {
        return { idUsuario: responsavel.idUsuario, nome: responsavel.nome };
      });

    const tipo = this.props.contrato.tipo
      ? this.props.contrato.tipo
      : this.state.tipo;

    const responsaveis =
      tipo === "tomador"
        ? this.props.contrato.responsaveis
          ? getListResponsaveis(this.props.contrato.responsaveis)
          : [
              {
                idUsuario: this.props.usuarioLogado.sub,
                nome: this.props.usuarioLogado.nome,
              },
            ]
        : null;

    const filial =
      tipo === "tomador"
        ? this.props.contrato.idContrato === "" && this.props.filial
          ? this.props.filial
          : this.props.contrato.tomador
        : this.props.contrato.idContrato === "" && this.props.filial
        ? this.props.filial
        : this.props.contrato.terceiro;

    let dadosContrato = {
      ...this.props.contrato,
      responsaveis,
      filial,
    };

    const infoEmail = tipo === "tomador"
      ? this.props.contrato.terceiro?.email ?? dadosContrato.email
      : this.props.contrato.tomador?.email ?? dadosContrato.email

    if (
      this.props.contrato.dataCriacao != null &&
      !(this.props.contrato.dataCriacao instanceof Date)
    ) {
      this.props.contrato.dataCriacao = new Date(
        this.props.contrato.dataCriacao.date
      );
    }

    if (dadosContrato.idContrato === "" && this.props.empresa) {
      dadosContrato.cnpj = this.props.empresa.cnpj;

      if (!document.getElementById("nome-empresa-contrato")) {
        setTimeout(() => {
          var event = new Event("keyup", { bubbles: true });
          document.getElementById("cnpj").dispatchEvent(event);
        }, 1000);
      }
    }

    return (
      <div className="pb-5">
        <div className="row mx-0 d-flex align-items-center justify-content-center">
          <div className="col-12 px-4">
            <div className="card">
              <div className="card-body px-2">
                <Formik
                  initialValues={dadosContrato}
                  onSubmit={this.handleSubmit.bind(this)}
                  render={({
                    isSubmitting,
                    setFieldValue,
                    values,
                    handleSubmit,
                    validateField,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      {this.props.contrato.idContrato !== "" && (
                        <div className="row mb-3">
                          <div className="col-6">
                            <div
                              style={{
                                borderRadius: "35px",
                                border:
                                  "2px solid " +
                                  (this.props.contrato.tipo === "terceiro"
                                    ? COLOR_LIGHT_BLUE
                                    : COLOR_GREEN),
                                background:
                                  this.props.contrato.tipo === "terceiro"
                                    ? COLOR_LIGHT_BLUE
                                    : COLOR_GREEN,
                                color: "#ffffff",
                                display: "block",
                                padding: "5px",
                                textAlign: "center",
                                fontWeight: 500,
                              }}
                            >
                              {this.props.contrato.tipo === "terceiro" && (
                                <FontAwesomeIcon
                                  icon="id-card-alt"
                                  className="mr-2"
                                />
                              )}
                              {this.props.contrato.tipo === "tomador" && (
                                <FontAwesomeIcon
                                  icon="building"
                                  className="mr-2"
                                />
                              )}
                              &nbsp;SOU {this.props.contrato.tipo.toUpperCase()}
                            </div>
                          </div>
                        </div>
                      )}
                      {this.props.contrato.idContrato === "" && (
                        <div className="row mb-3">
                          <div className="col-6">
                            <button
                              className="btn w-100"
                              onClick={() => {
                                setFieldValue("cnae", this.state.outro_cnaes);
                                let tmpFilial = values.terceiro;
                                let tmpTomador = values.tomador;
                                setFieldValue("terceiro", tmpTomador);
                                setFieldValue("tomador", tmpFilial);
                                setFieldValue(
                                  "responsaveis",
                                  this.props.contrato.responsaveis
                                    ? getListResponsaveis(
                                        this.props.contrato.responsaveis
                                      )
                                    : {
                                        idUsuario: this.props.usuarioLogado.sub,
                                        nome: this.props.usuarioLogado.nome,
                                      }
                                );
                                this.setState({ tipo: "tomador" });
                              }}
                              style={{
                                borderRadius: "35px",
                                border: "2px solid " + COLOR_GREEN,
                                background:
                                  tipo === "tomador" ? COLOR_GREEN : "#ffffff",
                                color:
                                  tipo === "tomador" ? "#ffffff" : COLOR_GREEN,
                                fontWeight: 500,
                              }}
                              type="button"
                            >
                              {tipo === "tomador" && (
                                <FontAwesomeIcon
                                  icon="building"
                                  className="mr-2"
                                />
                              )}
                              SOU TOMADOR
                            </button>
                          </div>
                          <div className="col-6">
                            <button
                              className="btn w-100"
                              onClick={() => {
                                setFieldValue("cnae", this.state.meus_cnaes);
                                let tmpFilial = values.tomador;
                                let tmpTerceiro = values.terceiro;
                                setFieldValue("terceiro", tmpFilial);
                                setFieldValue("tomador", tmpTerceiro);
                                setFieldValue("responsaveis", null);
                                this.setState({ tipo: "terceiro" });
                              }}
                              style={{
                                borderRadius: "35px",
                                border: "2px solid " + COLOR_LIGHT_BLUE,
                                background:
                                  tipo === "terceiro"
                                    ? COLOR_LIGHT_BLUE
                                    : "#ffffff",
                                color:
                                  tipo === "terceiro"
                                    ? "#ffffff"
                                    : COLOR_LIGHT_BLUE,
                                fontWeight: 500,
                              }}
                              type="button"
                            >
                              {tipo === "terceiro" && (
                                <FontAwesomeIcon
                                  icon="id-card-alt"
                                  className="mr-2"
                                />
                              )}
                              SOU TERCEIRO
                            </button>
                          </div>
                        </div>
                      )}
                      {this.props.contrato.idContrato === "" && (
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="cnpj">
                                CNPJ da Empresa{" "}
                                {tipo === "tomador" ? "Terceira" : "Tomadora"} *
                              </label>
                              <Field
                                className="form-control"
                                id="cnpj"
                                name="cnpj"
                                validate={cnpjField}
                              >
                                {({ field }) => {
                                  return (
                                    <Cnpj
                                      style={{
                                        border: this.state.cnpjFocus
                                          ? "2px solid rgba(255, 103, 0, 0.8)"
                                          : "",
                                      }}
                                      id="cnpj"
                                      tipo="filiais"
                                      mounted={this._isMounted}
                                      callback={(data) => {
                                        if (data.message) {
                                          MySwal.fire({
                                            title: data.message,
                                            type: "warning",
                                            toast: true,
                                            position: "center",
                                            timer: 2000,
                                            showConfirmButton: false,
                                          });
                                          return false;
                                        }
                                        this.setState({
                                          novaEmpresa: data.status === 0,
                                          cnpjFocus: false,
                                        });

                                        if (typeof data.nome === "undefined")
                                          return;
                                        setFieldValue("idFilial", "");
                                        setFieldValue("email", "");
                                        if (data.idFilial) {
                                          setFieldValue(
                                            "idFilial",
                                            data.idFilial
                                          );
                                        }
                                        if (data.email)
                                          setFieldValue("email", data.email);

                                        if (tipo === "tomador") {
                                          setFieldValue("cnae", data.cnae);
                                        } else if (
                                          data.meus_cnaes &&
                                          tipo === "terceiro"
                                        ) {
                                          setFieldValue(
                                            "cnae",
                                            data.meus_cnaes
                                          );
                                        }
                                        this.setState({
                                          outro_cnaes: data.cnae,
                                        });
                                        this.setState({
                                          meus_cnaes: data.meus_cnaes,
                                        });

                                        setFieldValue("nome", data.nome);
                                        setFieldValue(
                                          "telefone",
                                          data.telefone
                                        );
                                        setFieldValue(
                                          "endereco",
                                          data.logradouro
                                        );
                                        setFieldValue("numero", data.numero);
                                        setFieldValue(
                                          "cep",
                                          data.cep.replace(".", "")
                                        );
                                        setFieldValue("bairro", data.bairro);
                                        setFieldValue("cidade", data.cidade);
                                      }}
                                      {...field}
                                    ></Cnpj>
                                  );
                                }}
                              </Field>
                              <ErrorMessage
                                name="cnpj"
                                component="small"
                                className="form-text text-danger"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {!this.state.novaEmpresa && (
                        <fieldset className="mb-4">
                          <legend>
                            {tipo === "tomador" ? "Terceiro" : "Tomador"}
                          </legend>
                          <h5
                            className="font-weight-400 mb-3 ml-2"
                            style={{ color: COLOR_DARK2 }}
                          >
                            {values.nome ? (
                              <span id="nome-empresa-contrato">
                                {values.nome.toUpperCase()}
                              </span>
                            ) : (
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: COLOR_PRIMARY,
                                }}
                              >
                                <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                                Informe o CNPJ
                              </span>
                            )}
                          </h5>
                          {this.props.contrato.idContrato !== "" && (
                            <h6
                              className="font-weight-400 mb-3 ml-2"
                              style={{ color: COLOR_DARK2 }}
                            >
                              {values.cnpj}
                            </h6>
                          )}
                          {this.props.contrato.idContrato !== "" && (
                            <h6
                              className="font-weight-400 mb-3 ml-2"
                              style={{ color: COLOR_DARK2 }}
                            >
                              {infoEmail}
                            </h6>
                          )}
                        </fieldset>
                      )}
                      {this.props.contrato.idContrato !== "" &&
                        this.props.contrato.tipo === "tomador" && (
                          <ButtonPrimary
                            className="mb-3 d-block mx-auto"
                            type="button"
                            onClick={() => {
                              this.props.dispatch(setModalOpen("documentos"));
                            }}
                          >
                            <FontAwesomeIcon icon="clipboard-list" /> Seleção de
                            Documentos
                          </ButtonPrimary>
                        )}
                      {this.props.contrato.idContrato !== "" &&
                        this.props.contrato.tipo === "terceiro" &&
                        this.props.contrato.status !==
                          STATUS.AGUARDANDO_APROVACAO && (
                          <ButtonPrimary
                            className="mb-3 d-block mx-auto"
                            type="button"
                            onClick={() => {
                              this.props.dispatch(setModalOpen("funcionarios"));
                            }}
                          >
                            <FontAwesomeIcon icon="user-friends" /> Seleção de
                            Funcionários
                          </ButtonPrimary>
                        )}
                      {this.state.novaEmpresa && (
                        <div className="w-100">
                          <div
                            className="col-12 text-center"
                            style={{ color: COLOR_PRIMARY, fontSize: "16px" }}
                          >
                            <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                            Empresa não cadastrada, informe os dados:{" "}
                            <span
                              style={{ fontSize: "10px", color: COLOR_DARK }}
                            >
                              <br />
                              (O e-mail informado irá receber um link para
                              realizar o seu cadastro)
                            </span>
                          </div>
                          <fieldset className="mb-4">
                            <legend>
                              {tipo === "tomador" ? "Terceiro" : "Tomador"}
                            </legend>
                            <div className="row m-0">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="nome">Nome *</label>
                                  <Field
                                    type="text"
                                    id="nome"
                                    name="nome"
                                    className="form-control"
                                    validate={requiredField}
                                  />
                                  <ErrorMessage
                                    name="nome"
                                    component="small"
                                    className="form-text text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="email">E-mail *</label>
                                  <Field
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    validate={emailField}
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="small"
                                    className="form-text text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="telefone">Telefone *</label>
                                  <Field name="telefone" validate={phoneField}>
                                    {({ field }) => {
                                      return (
                                        <Telefone
                                          {...field}
                                          id="telefone"
                                        ></Telefone>
                                      );
                                    }}
                                  </Field>
                                  <ErrorMessage
                                    name="telefone"
                                    component="small"
                                    className="form-text text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="cep">CEP *</label>
                                  <Field name="cep" validate={cepField}>
                                    {({ field }) => {
                                      return (
                                        <Cep
                                          onBlur={() => validateField("cep")}
                                          mounted={this._isMounted}
                                          callback={(data) => {
                                            if (data.erro !== true) {
                                              setFieldValue(
                                                "endereco",
                                                data.logradouro
                                              );
                                              setFieldValue(
                                                "bairro",
                                                data.bairro
                                              );
                                              setFieldValue(
                                                "pontoReferencia",
                                                data.complemento
                                              );
                                              setFieldValue(
                                                "cidade",
                                                data.cidade
                                              );
                                            }
                                          }}
                                          {...field}
                                          id="cep"
                                        ></Cep>
                                      );
                                    }}
                                  </Field>
                                  <ErrorMessage
                                    name="cep"
                                    component="small"
                                    className="form-text text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="endereco">Endereço *</label>
                                  <Field
                                    type="text"
                                    id="endereco"
                                    name="endereco"
                                    className="form-control"
                                    validate={requiredField}
                                  />
                                  <ErrorMessage
                                    name="endereco"
                                    component="small"
                                    className="form-text text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="numero">Número</label>
                                  <Field
                                    type="text"
                                    id="numero"
                                    name="numero"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="numero"
                                    component="small"
                                    className="form-text text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="numero">Bairro *</label>
                                  <Field
                                    type="text"
                                    id="bairro"
                                    name="bairro"
                                    className="form-control"
                                    validate={requiredField}
                                  />
                                  <ErrorMessage
                                    name="bairro"
                                    component="small"
                                    className="form-text text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="pontoReferencia">
                                    Ponto de Referência
                                  </label>
                                  <Field
                                    type="text"
                                    id="pontoReferencia"
                                    name="pontoReferencia"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="pontoReferencia"
                                    component="small"
                                    className="form-text text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="cidade" className="w-100">
                                    Cidade *
                                  </label>
                                  <Field name="cidade" validate={objectField}>
                                    {({ field }) => {
                                      return (
                                        <AsyncSelect
                                          {...field}
                                          placeholder="Digite para localizar..."
                                          id="cidade"
                                          isSearchable
                                          defaultValue={values.cidade}
                                          getOptionValue={(option) =>
                                            option["idCidade"]
                                          }
                                          getOptionLabel={(option) =>
                                            option["nome"] +
                                            " - " +
                                            option["estado"]["uf"]
                                          }
                                          loadOptions={loadCidadesOptions}
                                          onChange={(value) => {
                                            setFieldValue("cidade", value);
                                          }}
                                          noOptionsMessage={(input) => {
                                            return input.inputValue === ""
                                              ? "Digite para localizar..."
                                              : "Nenhum resultado encontrado";
                                          }}
                                          loadingMessage={() => "Carregando..."}
                                          theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                              ...theme.colors,
                                              primary50: "#ebebeb",
                                              primary25: "#ebebeb",
                                              primary: "#ff5818",
                                            },
                                          })}
                                        />
                                      );
                                    }}
                                  </Field>
                                  <ErrorMessage
                                    name="cidade"
                                    component="small"
                                    className="form-text text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="cnae" className="w-100">
                              CNAE's *
                            </label>
                            <Field name="cnae[]">
                              {({ field }) => {
                                return (
                                  <AsyncSelect
                                    {...field}
                                    isMulti
                                    placeholder="Digite para localizar..."
                                    id="cnae"
                                    isSearchable
                                    value={values.cnae}
                                    getOptionValue={(option) =>
                                      option["codigo"]
                                    }
                                    getOptionLabel={(option) =>
                                      option["codigo"] +
                                      " - " +
                                      option["descricao"]
                                    }
                                    loadOptions={loadCnaesOptions}
                                    onChange={(value) => {
                                      setFieldValue("cnae", value);
                                    }}
                                    noOptionsMessage={(input) => {
                                      return input.inputValue === ""
                                        ? "Digite para localizar..."
                                        : "Nenhum resultado encontrado, por favor adicione o seu CNAE";
                                    }}
                                    loadingMessage={() => "Carregando..."}
                                    styles={{
                                      control: (styles) => ({
                                        ...styles,
                                        cursor: "pointer",
                                      }),
                                      option: (styles) => ({
                                        ...styles,
                                        cursor: "pointer",
                                      }),
                                    }}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary50: "#ebebeb",
                                        primary25: "#ebebeb",
                                        primary: "#ff5818",
                                      },
                                    })}
                                  />
                                );
                              }}
                            </Field>
                            <ErrorMessage
                              name="cnae"
                              component="small"
                              className="form-text text-danger"
                            />
                            <button
                              type="button"
                              onClick={() => {
                                this.handleCnaeCreation((dt) => {
                                  let cnae = values.cnae;
                                  cnae.push({
                                    codigo: dt.codigo,
                                    descricao: dt.descricao,
                                    empresaCriadora: null,
                                    status: 1,
                                  });
                                  setFieldValue("cnae", cnae);
                                });
                              }}
                              className="small w-auto float-right"
                              style={{ color: COLOR_PRIMARY, top: 0 }}
                            >
                              Não encontrou na lista? Sugerir CNAE
                            </button>
                          </div>
                        </div>
                      </div>
                      {this.props.permissoes.administradorEmpresa === 1 && (
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="filial" className="w-100">
                                Filial *
                              </label>
                              <Field name="filial" validate={objectField}>
                                {({ field }) => {
                                  return (
                                    <AsyncSelect
                                      {...field}
                                      placeholder="Digite para localizar..."
                                      id="filial"
                                      value={values.filial}
                                      isSearchable
                                      getOptionValue={(option) =>
                                        option["idFilial"]
                                      }
                                      getOptionLabel={(option) =>
                                        option["matriz"] === 1
                                          ? option["nome"].toUpperCase() +
                                            " - MATRIZ"
                                          : option["nome"].toUpperCase()
                                      }
                                      loadOptions={loadFiliaisOptions}
                                      defaultOptions={true}
                                      onChange={(value) => {
                                        setFieldValue("filial", value);
                                      }}
                                      noOptionsMessage={(input) => {
                                        return input.inputValue === ""
                                          ? "Digite para localizar..."
                                          : "Nenhum resultado encontrado";
                                      }}
                                      loadingMessage={() => "Carregando..."}
                                      styles={{
                                        control: (styles) => ({
                                          ...styles,
                                          cursor: "pointer",
                                        }),
                                        option: (styles) => ({
                                          ...styles,
                                          cursor: "pointer",
                                        }),
                                      }}
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          primary50: "#ebebeb",
                                          primary25: "#ebebeb",
                                          primary: "#ff5818",
                                        },
                                      })}
                                    />
                                  );
                                }}
                              </Field>
                              <ErrorMessage
                                name="filial"
                                component="small"
                                className="form-text text-danger"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="titulo">Título *</label>
                            <Field
                              type="text"
                              id="titulo"
                              name="titulo"
                              className="form-control"
                              validate={requiredField}
                            />
                            <ErrorMessage
                              name="titulo"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="descricao">Descrição</label>
                            <Field
                              rows="3"
                              component="textarea"
                              id="descricao"
                              name="descricao"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="descricao"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label htmlFor="dataInicio">Data de Início *</label>
                            <Field name="dataInicio" validate={dateField}>
                              {({ field }) => {
                                return (
                                  <Calendar
                                    name="dataInicio"
                                    id="dataInicio"
                                    className="form-control"
                                    disabledKeyboardNavigation
                                    showLeadingZeros={true}
                                    locale={pt}
                                    maxDate={values.dataFim}
                                    selected={values.dataInicio}
                                    placeholderText="-- / -- / ----"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      setFieldValue("dataInicio", date);
                                    }}
                                    customInput={
                                      <MaskedTextInput
                                        mask={[
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                        ]}
                                      />
                                    }
                                  />
                                );
                              }}
                            </Field>
                            <ErrorMessage
                              name="dataInicio"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label htmlFor="dataFim">Data de Fim *</label>
                            <Field name="dataFim" validate={dateField}>
                              {({ field }) => {
                                return (
                                  <Calendar
                                    name="dataFim"
                                    id="dataFim"
                                    className="form-control"
                                    locale={pt}
                                    showLeadingZeros={true}
                                    minDate={values.dataInicio}
                                    selected={values.dataFim}
                                    placeholderText="-- / -- / ----"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      setFieldValue("dataFim", date);
                                    }}
                                    customInput={
                                      <MaskedTextInput
                                        mask={[
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          "/",
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                        ]}
                                      />
                                    }
                                  />
                                );
                              }}
                            </Field>
                            <ErrorMessage
                              name="dataFim"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.tipo === "tomador" && (
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="responsaveis" className="w-100">
                                Responsáveis
                              </label>
                              <Field name="responsaveis">
                                {({ field }) => {
                                  if (this.props.contrato.tipo === "terceiro")
                                    return (
                                      <div className="form-control">
                                        {!this.props.contrato.responsaveis
                                          ? "Sem responsáveis"
                                          : this.props.contrato.responsaveis
                                              .map((resp) => resp.nome)
                                              .join("; ")}
                                      </div>
                                    );
                                  return (
                                    <AsyncSelect
                                      {...field}
                                      isMulti
                                      isRequired={false}
                                      placeholder="Digite para localizar..."
                                      id="responsaveis"
                                      isSearchable
                                      isClearable
                                      loadOptions={fetchUsuariosByFilialOptions}
                                      getOptionValue={(option) =>
                                        option["idUsuario"]
                                      }
                                      getOptionLabel={(option) =>
                                        option["nome"].toUpperCase()
                                      }
                                      defaultOptions={true}
                                      value={values.responsaveis}
                                      onChange={(value) => {
                                        setFieldValue("responsaveis", value);
                                      }}
                                      noOptionsMessage={(input) => {
                                        return input.inputValue === ""
                                          ? "Digite para localizar..."
                                          : "Nenhum resultado encontrado";
                                      }}
                                      loadingMessage={() => "Carregando..."}
                                      styles={{
                                        control: (styles) => ({
                                          ...styles,
                                          cursor: "pointer",
                                        }),
                                        option: (styles) => ({
                                          ...styles,
                                          cursor: "pointer",
                                        }),
                                      }}
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          primary50: "#ebebeb",
                                          primary25: "#ebebeb",
                                          primary: "#ff5818",
                                        },
                                      })}
                                    />
                                  );
                                }}
                              </Field>
                              <ErrorMessage
                                name="responsaveis"
                                component="small"
                                className="form-text text-danger"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-center pt-4">
                          {this.props.contrato.idContrato === "" && (
                            <ButtonSuccess
                              type="submit"
                              disabled={isSubmitting}
                            >
                              <FontAwesomeIcon icon="arrow-right" /> Continuar
                            </ButtonSuccess>
                          )}
                          {this.props.contrato.status === STATUS.ATIVO &&
                            this.props.contrato.idContrato !== "" &&
                            this.props.contrato.tipo === "tomador" && (
                              <ButtonSuccess
                                type="submit"
                                disabled={isSubmitting}
                              >
                                <FontAwesomeIcon icon="save" /> Salvar
                              </ButtonSuccess>
                            )}
                          {this.props.contrato.idContrato !== "" &&
                            this.props.contrato.status === STATUS.INATIVO &&
                            this.props.contrato.tipo === "tomador" && (
                              <ButtonSuccess
                                type="button"
                                onClick={this.handleChangeStatus.bind(this)}
                                disabled={isSubmitting}
                              >
                                <FontAwesomeIcon icon="check" /> Ativar
                              </ButtonSuccess>
                            )}
                          {this.props.contrato.idContrato !== "" &&
                            this.props.contrato.status ===
                              STATUS.AGUARDANDO_APROVACAO &&
                            this.props.contrato.tipo === "terceiro" && (
                              <ButtonSuccess
                                type="button"
                                onClick={this.handleChangeStatus.bind(this)}
                                disabled={isSubmitting}
                              >
                                <FontAwesomeIcon icon="check" /> Ativar
                              </ButtonSuccess>
                            )}
                          {this.props.contrato.idContrato !== "" &&
                            this.props.contrato.status === STATUS.ATIVO &&
                            this.props.contrato.tipo === "tomador" && (
                              <ButtonWarning
                                type="button"
                                onClick={this.handleChangeStatus.bind(this)}
                                className="ml-3"
                                disabled={isSubmitting}
                              >
                                <FontAwesomeIcon icon="times" /> Bloquear
                              </ButtonWarning>
                            )}
                          {this.props.contrato.idContrato !== "" &&
                            this.props.contrato.tipo === "tomador" && (
                              <ButtonGray
                                type="button"
                                onClick={this.handleDelete.bind(this)}
                                className="ml-3"
                                disabled={isSubmitting}
                              >
                                <FontAwesomeIcon icon="trash" /> Excluir
                              </ButtonGray>
                            )}
                        </div>
                      </div>
                      {this.props.contrato.usuarioCriacao != null && (
                        <>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="usuarioCriacao">
                                  Criado Por
                                </label>
                                <div className="form-control">
                                  {this.props.contrato.usuarioCriacao.nome}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="usuarioCriacao">
                                  Criado Em
                                </label>
                                <div className="form-control">
                                  {this.props.contrato.dataCriacao.toLocaleDateString(
                                    "pt-BR"
                                  )}{" "}
                                  {this.props.contrato.dataCriacao.toLocaleTimeString(
                                    "pt-BR"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContratoForm.propTypes = {
  contrato: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  id: PropTypes.any,
  onAfterSave: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  permissoes: PropTypes.object.isRequired,
  cnaes: PropTypes.object.isRequired,
  usuarioLogado: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  contrato: state.contratos.contrato,
  loading: state.contratos.loading,
  error: state.contratos.error,
  errors: state.errors,
  id: state.modal.id,
  cnaes: state.cnaes,
  permissoes: state.permissions.permissoes,
  usuarioLogado: state.auth.user,
});

export default connect(mapStateToProps)(withRouter(ContratoForm));
